import { useEffect, useState } from 'react';

export default function useModal(startsOpen = false) {
  const [isOpen, setIsOpen] = useState(startsOpen);

  useEffect(() => {
    setIsOpen(startsOpen);
  }, [startsOpen]);

  useEffect(() => {
    if (isOpen) {
      document.querySelector(':root').style.overflow = 'hidden';
    } else {
      document.querySelector(':root').style.overflow = 'auto';
    }
  }, [isOpen]);

  function onClose(fn = () => {}) {
    fn();
    setIsOpen(false);
  }

  function onOpen(fn = () => {}) {
    fn();
    setIsOpen(true);
  }

  return {
    isOpen,
    onClose,
    onOpen,
  };
}
