import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  min-height: 60px;
  overflow: hidden;
`;

const Label = styled.label`
  position: absolute;
  color: #828282;
  transition: color 0.3s ease-in-out;
  text-transform: initial;
  padding: 0.5rem;
  pointer-events: none;
  bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s ease-in-out;
`;

const LabelHandlerStyle = css`
  & ~ ${Label} {
    color: ${({ theme }) => theme.colors.primary_dark};
    text-transform: initial;
    bottom: 35px;
    padding-left: 0;
    font-weight: 500;
    font-size: 14px;
    pointer-events: unset;
    padding-bottom: 4px;
  }
`;

const StyledInput = styled.input`
  ${({ theme, uppercasedInput }) => css`
    background-color: ${theme.colors.secondary_light};
    padding: 0.5rem;
    color: ${theme.colors.black};
    font-size: 16px;
    line-height: 22px;
    border: 0;
    width: 100%;
    height: 40px;
    outline: none;
    border-bottom: 1px solid transparent;
    align-self: flex-end;
    ${uppercasedInput &&
        css`
          text-transform: uppercase;
        `}
      :focus {
      border-bottom: 1px solid ${theme.colors.primary_dark};
      transition: border-bottom 0.3s ease-in-out;
    }

    :focus,
    :valid,
    :-internal-autofill-selected {
      ${LabelHandlerStyle}
    }
    :focus,
    :valid,
    :autofill {
      ${LabelHandlerStyle}
    }
  `}
`;

const IconButton = styled.button`
  ${({ theme }) => css`
    cursor: pointer;
    border: 0;
    outline: none;
    background-color: transparent;
    font-size: 18px;
    padding: 0.5rem;
    height: 40px;
    width: 40px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 20px;
    & > svg {
      & > path {
        fill: ${theme.colors.primary};
      }
    }

    :hover,
    :focus {
      & > svg {
        & > path {
          fill: ${theme.colors.primary_dark};
        }
      }
    }
  `}
`;

export default function Input({
  id,
  placeholder,
  type = 'text',
  uppercasedInput = false,
  ...props
}) {
  const [showPassword, toggleShowPassword] = useState(false);

  const handleInputType = () => {
    if (type !== 'password') {
      return type;
    }
    return showPassword ? 'text' : 'password';
  };

  const renderPasswordIcon = () => (
    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
  );

  return (
    <Container>
      <StyledInput
        id={id}
        type={handleInputType()}
        uppercasedInput={uppercasedInput}
        {...props}
      />
      <Label htmlFor={id}>{placeholder}</Label>
      {type === 'password' && (
        <IconButton
          aria-label={showPassword ? 'Esconder senha' : 'Mostrar senha'}
          type="button"
          onClick={() => toggleShowPassword(!showPassword)}
        >
          {renderPasswordIcon()}
        </IconButton>
      )}
    </Container>
  );
}
