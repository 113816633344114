import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Button from './button';
import Card from './card';
import Form from './form';
import Input from './input';

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  margin: 0.4rem 0 1rem;

  display: flex;
  align-items: center;

  > svg {
    margin-right: 8px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 8px;
  & > button {
    flex: 0.3;
  }
`;

const ForgotPasswordButton = styled.a`
  flex: 0.7;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  font-weight: 600;
  text-transform: none;
  text-decoration: underline;
  outline: none;

  :hover,
  :focus {
    filter: brightness(0.7);
  }
`;

const LoginIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0542 13.1821C14.0643 14.148 13.2885 14.9402 12.3225 14.9502L9.82268 14.9762C9.62378 14.9783 9.4322 14.9012 9.29009 14.7621C9.14799 14.6229 9.06699 14.4329 9.06492 14.234C9.06285 14.0351 9.13988 13.8436 9.27906 13.7015C9.41824 13.5594 9.60818 13.4784 9.80708 13.4763L12.3069 13.4503C12.3732 13.4496 12.4366 13.4226 12.4829 13.3752C12.5293 13.3279 12.555 13.264 12.5543 13.1977L12.4451 2.69827C12.4437 2.56028 12.3305 2.44945 12.1925 2.45088L9.69268 2.47688C9.49378 2.47895 9.3022 2.40192 9.1601 2.26274C9.01799 2.12356 8.93699 1.93362 8.93492 1.73472C8.93285 1.53582 9.00988 1.34424 9.14907 1.20214C9.28825 1.06003 9.47818 0.979031 9.67708 0.976962L12.1769 0.950963C12.6411 0.946136 13.0881 1.12587 13.4196 1.45063C13.7512 1.77539 13.9402 2.21857 13.9451 2.68267L14.0542 13.1821ZM7.4478 8.75057L5.4984 10.7409C5.42543 10.8104 5.36719 10.8938 5.32716 10.9862C5.28713 11.0786 5.26612 11.1782 5.26539 11.2789C5.26466 11.3796 5.28422 11.4794 5.32291 11.5724C5.3616 11.6654 5.41863 11.7497 5.49058 11.8201C5.56254 11.8906 5.64795 11.9459 5.74173 11.9826C5.8355 12.0194 5.93572 12.0368 6.0364 12.034C6.13708 12.0312 6.23616 12.0081 6.32772 11.9662C6.41929 11.9242 6.50147 11.8643 6.56937 11.7899L9.78539 8.50624C9.92437 8.36417 10.0013 8.17273 9.99921 7.97399C9.99714 7.77525 9.91627 7.58546 9.77437 7.4463L6.49074 4.23028C6.42132 4.15731 6.33791 4.09907 6.24549 4.05904C6.15307 4.019 6.05353 3.99799 5.95281 3.99727C5.8521 3.99654 5.75227 4.0161 5.65928 4.05479C5.56629 4.09348 5.48204 4.1505 5.41157 4.22246C5.34109 4.29442 5.28583 4.37983 5.24908 4.4736C5.21234 4.56738 5.19485 4.6676 5.19768 4.76827C5.2005 4.86895 5.22357 4.96803 5.26552 5.0596C5.30747 5.15117 5.36743 5.23335 5.44183 5.30124L7.43221 7.25065L1.74251 7.30982C1.54361 7.31189 1.35368 7.39289 1.2145 7.535C1.07531 7.6771 0.998285 7.86868 1.00035 8.06758C1.00242 8.26648 1.08342 8.45642 1.22553 8.5956C1.36763 8.73478 1.55921 8.81181 1.75811 8.80974L7.4478 8.75057V8.75057Z"
      fill="currentColor"
    />
  </svg>
);

export default function LoginCard({ onSubmit }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    site: {
      siteMetadata: { portalSocioEndpoint },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            portalSocioEndpoint
          }
        }
      }
    `,
  );

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      setLoading(true);
      setErrorMessage('');
      const payload = {
        username: e.target.registration.value,
        password: e.target['current-password'].value,
      };
      await onSubmit(payload);
    } catch (error) {
      if (error.response.status === 401) {
        setErrorMessage('Credenciais inválidas');
      } else {
        setErrorMessage('Ocorreu um erro. Por favor, tente novamente');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card backgroundColor="#ffffff">
      <Title>
        <LoginIcon />
        Login
      </Title>
      <Form id="login-form" onSubmit={handleSubmit} errorMessage={errorMessage}>
        <Input
          required
          id="registration"
          placeholder="Matrícula"
          pattern="\d+"
          title="São aceitos somente números"
          maxLength="8"
          onClick={(e) => e.stopPropagation()}
        />
        <Input
          required
          id="current-password"
          placeholder="Senha"
          type="password"
          maxLength="6"
          onClick={(e) => e.stopPropagation()}
        />
      </Form>
      <ButtonsWrapper>
        <ForgotPasswordButton
          href={portalSocioEndpoint}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          Esqueci minha senha
        </ForgotPasswordButton>
        <Button
          form="login-form"
          type="submit"
          onClick={(e) => e.stopPropagation()}
          style={{ height: '40px', maxWidth: '115px' }}
          isLoading={loading}
        >
          Entrar
        </Button>
      </ButtonsWrapper>
    </Card>
  );
}
