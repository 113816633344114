import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled.div`
  width: ${(props) => props.width};
  box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08),
    0 1px 3px 1px rgba(60, 64, 67, 0.16);
  border-radius: 3px;
  padding: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.1rem;
  background-color: ${({ backgroundColor }) => backgroundColor || 'unset'};

  h1 {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.7rem;
    min-height: 5.1rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 250ms ease;
  }

  figure {
    margin-bottom: 1.2rem;
    margin-left: -1rem;
    margin-right: -1rem;
    height: calc(194 / 344 * ${(props) => props.width});
    overflow: hidden;

    img {
      margin: 0;
      transition: all 700ms ease;
      transform: scale(1);
    }

    &:first-child {
      margin-top: -1rem;
    }
  }

  &:hover {
    figure:first-child {
      img {
        transform: scale(1.1);
      }
    }

    h1 {
      color: ${({ theme }) => theme.color.primary_dark};
    }
  }

  div.tag {
    color: ${({ theme }) => theme.color.primary_dark};
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  small {
    font-size: 0.8rem;
    opacity: 0.7;
    line-height: 1rem;
  }

  p:only-of-type {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

function Card(props) {
  const { width, children, to, backgroundColor } = props;

  if (to.length > 0) {
    return (
      <Link to={to}>
        <Container width={width}>{children}</Container>
      </Link>
    );
  }
  return (
    <Container backgroundColor={backgroundColor} width={width}>
      {children}
    </Container>
  );
}

Card.propTypes = {
  width: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
};

Card.defaultProps = {
  width: '344px',
  to: '',
  backgroundColor: '',
};

export default Card;
