import React, { useState } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { navigate } from 'gatsby-link';
import { useAuth } from '../context';
import LoginCard from './loginCard';
import CTA from './call-to-action';
import FullscreenModal from './FullscreenModal';
import logoImage from '../images/clube-do-povo.svg';
import Portal from './portal';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
`;

const Logo = styled.img`
  width: 50%;
`;

const Paragraph = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin: 32px 0;
`;

const LinkButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: #adadad;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0 16px;

  @media screen and (max-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    justify-content: space-between;
  }
`;

export default React.memo(({ isOpen, onClose, redirectTo }) => {
  const [dialogContent, setDialogContent] = useState(true);

  const { login } = useAuth();

  const onSubmit = async (payload) => {
    const successfulSignIn = await login(payload);
    /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
    successfulSignIn && onClose();
    if (redirectTo) {
      navigate(redirectTo);
    }
  };

  const contentRenderer = (
    <MainContainer>
      <Logo src={logoImage} alt="Logo do Sport Club Internacional" />
      {dialogContent ? (
        <>
          <Paragraph>
            Este conteúdo é exclusivo para sócios do Sport Club Internacional.
            Associe-se e tenha acesso a esta e muitas outras vantagens.
          </Paragraph>
          <ButtonsWrapper>
            <CTA to="/associe-se" label="Seja sócio" />
            <LinkButton
              onClick={(e) => {
                e.stopPropagation();
                setDialogContent(false);
              }}
            >
              Já sou sócio
            </LinkButton>
          </ButtonsWrapper>
        </>
      ) : (
        <>
          <LoginCard onSubmit={onSubmit} />
          <LinkButton
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href="/associe-se"
            onClick={(e) => e.stopPropagation()}
            style={{ marginTop: '32px' }}
          >
            Ainda não sou sócio
          </LinkButton>
        </>
      )}
    </MainContainer>
  );

  return (
    <Portal>
      <FullscreenModal onClose={onClose} open={isOpen}>
        {contentRenderer}
      </FullscreenModal>
    </Portal>
  );
});
