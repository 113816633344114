import React from 'react';
import styled from 'styled-components';
import ErrorMessage from './formErrorMessage';

const StyledForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;

  & > div + div {
    margin-top: 12px;
  }
`;

export default function Form({ children, onSubmit, errorMessage, ...props }) {
  const renderError = errorMessage && (
    <ErrorMessage>{errorMessage}</ErrorMessage>
  );
  return (
    <StyledForm
      {...props}
      aria-describedby="form-error-message"
      onSubmit={(e) => onSubmit(e)}
    >
      {children}
      {renderError}
    </StyledForm>
  );
}
